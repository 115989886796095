<template>
  <Cover>
    <template v-slot:content>
    <div class="container text-start d-flex py-xl-5 py-md-5 mb-4">
      <div class="justify-content-center align-self-center">
        <object class="mt-5 " id="svg1"
                alt="Bunker Arena Logo"
                :data="require('../assets/svg/logo.svg')"
                width="350" type="image/svg+xml">
          Logo
        </object>
        <div class="my-4">
          <h1 class="display-65"> CEL MAI PERFORMANT CENTRU DE LASER TAG</h1>
          <h6>
            Adunā-ţi prietenii pentru o experienta plinā de adrenalinā
          </h6>
        </div>
        <router-link to="/rezervari" class="my-2 btn btn-lg btn-blue">
          Rezervāri laser tag
        </router-link>
      </div>
    </div>
    </template>
  </Cover>
</template>

<script>
import Cover from './Cover.vue';

export default {
  name: 'CoverHome',
  components: {
    Cover,
  },
};
</script>
