<template>
  <article class="postcard dark" :class="position + ' ' + color">
      <router-link class="postcard__img_link" :to="linkTo">
        <img class="postcard__img lazyload"
             width="512"
             height="200"
             :data-src="img"
             :alt="titleText" />
      </router-link>
      <div class="postcard__text">
        <h4 class="text-start blue m-0">
          <router-link :to="linkTo">{{titleText}}</router-link>
        </h4>
        <div class="postcard__bar"></div>
        <div class="postcard__preview-txt">
          {{bodyText}}
        </div>
      </div>
    </article>
</template>

<script>
import 'lazysizes';

export default {
  name: 'Card',
  props: {
    position: {
      type: String,
      default: 'left',
    },
    linkTo: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: 'blue',
    },
    img: {
      type: String,
      default: 'https://picsum.photos/1000/1000',
    },
    titleText: {
      type: String,
      default: '',
    },
    bodyText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  $main-yellow: #bdbb49 !default;
  $main-yellow-rgb-015: rgba(189, 187, 73, 0.1) !default;
  $main-red: #bd150b !default;
  $main-red-rgb-015: rgba(189, 21, 11, 0.1) !default;
  $main-blue: #0076bd !default;
  $main-blue-rgb-015: rgba(0, 118, 189, 0.1) !default;

  .dark {
    background: #110f16;
  }

  a, a:hover {
    text-decoration: none;
  }

  /* Cards */
  .postcard {
    flex-wrap: wrap;
    display: flex;

    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #ffffff;

    &.dark {
      background-color: #18151f;
    }

    .t-dark {
      color: #18151f;
    }

    a {
      color: inherit;
    }

    h1, .h1 {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .small {
      font-size: 80%;
    }

    .postcard__title {
      font-size: 1.75rem;
    }

    .postcard__img {
      max-height: 180px;
      width: 100%;
      object-fit: cover;
      position: relative;
    }

    .postcard__img_link {
      display: contents;
    }

    .postcard__bar {
      width: 50px;
      height: 2px;
      margin: 2px 0;
      border-radius: 5px;
      background-color: #424242;
      transition: width 0.2s ease;
    }

    .postcard__text {
      padding: 1.5rem;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .postcard__preview-txt {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      height: 100%;
    }

    .postcard__tagbox {
      display: flex;
      flex-flow: row wrap;
      font-size: 14px;
      margin: 20px 0 0 0;
      padding: 0;
      justify-content: center;

      .tag__item {
        display: inline-block;
        background: rgba(83, 83, 83, 0.4);
        border-radius: 3px;
        padding: 2.5px 10px;
        margin: 0 5px 5px 0;
        cursor: default;
        user-select: none;
        transition: background-color 0.3s;

        &:hover {
          background: rgba(83, 83, 83, 0.8);
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(-70deg, #424242, transparent 50%);
      opacity: 1;
      border-radius: 10px;
    }

    &:hover .postcard__bar {
      width: 90%;
    }
  }

  @media screen and (min-width: 769px) {
    .postcard {
      flex-wrap: inherit;

      .postcard__title {
        font-size: 2rem;
      }

      .postcard__tagbox {
        justify-content: start;
      }

      .postcard__img {
        max-width: 200px;
        max-height: 100%;
        transition: transform 0.3s ease;
      }

      .postcard__text {
        padding: 1.5rem;
        width: 100%;
      }

      .media.postcard__text:before {
        position: absolute;
        display: block;
        background: #18151f;
        top: -20%;
        height: 130%;
        width: 55px;
      }

      &:hover .postcard__img {
        transform: scale(1.1);
      }
    }
  }
  @media screen and (min-width: 1024px){
    .postcard {
      height: 200px;
    }
    .postcard__text {
      padding: 2rem 3.5rem;
    }

    .postcard__text:before {
      position: absolute;
      display: block;

      top: -20%;
      height: 130%;
      width: 55px;
    }

    .postcard.dark {
      .postcard__text:before {
        background: #18151f;
      }
    }
  }

  .right {
    flex-direction: row;
  }

  .left {
    flex-direction: row-reverse;
  }

  .right .postcard__text::before {
    left: -20px !important;
    transform: rotate(4deg);
  }

  .left .postcard__text::before {
    right: -10px !important;
    transform: rotate(-4deg);
  }

  /* COLORS */
  .postcard .postcard__tagbox .blue.play:hover {
    background: $main-blue;
  }
  .blue .postcard__title:hover {
    color: $main-blue;
  }
  .blue .postcard__bar {
    background-color: $main-blue;
  }
  .blue::before {
    background-image: linear-gradient(-30deg, $main-blue-rgb-015, transparent 50%);
  }
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(30deg, $main-blue-rgb-015, transparent 50%);
  }

  .postcard .postcard__tagbox .red.play:hover {
    background: $main-red;
  }
  .red .postcard__title:hover {
    color: $main-red;
  }
  .red .postcard__bar {
    background-color: $main-red;
  }
  .red .right::before {
    background-image: linear-gradient(-30deg, $main-red-rgb-015, transparent 0%);
  }
  .red:nth-child(2n)::before {
    background-image: linear-gradient(30deg, $main-red-rgb-015, transparent 0%);
  }

  .postcard .postcard__tagbox .yellow.play:hover {
    background: $main-yellow;
    color: black;
  }
  .yellow .postcard__title:hover {
    color: $main-yellow;
  }
  .yellow .postcard__bar {
    background-color: $main-yellow;
  }
  .yellow:nth-child(2n)::before {
    background-image: linear-gradient(
        30deg,
        $main-yellow-rgb-015,
        transparent 50%
    );
  }

  @media screen and (min-width: 769px) {
    .blue.right::before {
      background-image: linear-gradient(
          -80deg,
          $main-blue-rgb-015,
          transparent 50%
      );
    }
    .blue.left::before {
      background-image: linear-gradient(80deg, $main-blue-rgb-015, transparent 50%);
    }

    .red.right::before {
      background-image: linear-gradient(-80deg, $main-red-rgb-015, transparent 50%);
    }
    .red.left::before {
      background-image: linear-gradient(80deg, $main-red-rgb-015, transparent 50%);
    }

    .yellow.right::before {
      background-image: linear-gradient(
          -80deg,
          $main-yellow-rgb-015,
          transparent 50%
      );
    }
    .yellow.left::before {
      background-image: linear-gradient(
          80deg,
          $main-yellow-rgb-015,
          transparent 50%
      );
    }
  }

</style>
