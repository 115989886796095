<template>
  <div>
    <CoverHome class="vh-65"></CoverHome>
    <div class="container vh-35 mt-5 mb-4 text-start">
      <div class="display-7">
        Ce se întâmplă în Bunker?
      </div>
      <h5>
        In momentul în care ai pășit la noi, poți fi sigur că vei avea parte de o experiență unică!
      </h5>
      <img src="@/assets/divider.svg"
           height="8"
           width="100"
           class="my-2 mb-4" alt="Bunker divider">
      <p class="h6">
        Echipa noastră te așteaptă cu
        <strong>LaserTag</strong>,
        <strong>LaserMaze</strong>,
        <strong>Console PS VR</strong>,
        <strong>Playseat Racing Rig</strong>,
        <strong>Karaoke Room</strong>, și zeci de <strong>Boardgames</strong>.
        <br>
        Dincolo de activitățile oferite, Bunker este pentru noi o a doua casa și, te
        invităm și pe tine sa faci parte din marea noastră familie!
      </p>
      <div class="row mt-5">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <Card position="left"
                :link-to="{ name: 'album', params: { section: 'laser-tag' }}"
                color="blue"
                title-text="Laser Tag"
                :img="require('../assets/thumbs/bunker-arena-laser-tag.webp')"
                body-text="Într-o Arena de 300 mp, cu decor fluorescent, doua baze,
                un Domination Box și zeci de scenarii fictive de lupta, vei ieși învingător,
                indiferent de scor. Vei căpăta spirit de echipa, coordonare, iti poți descoperi
                abilități de leader sau de troll :) "></Card>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <Card position="right"
                :link-to="{ name: 'album', params: { section: 'party' }}"
                color="red"
                :img="require('../assets/thumbs/bunker-arena-party.webp')"
                title-text="Petreceri private"
                body-text="Dacă este ziua ta, te invităm la Bunker!
                Ai locația doar pentru tine și prietenii tăi. Timp de 3 ore, ai
                acces nelimitat la tot ceea ce oferim noi! Dacă vrei detalii, ne suni,
                iar noi îți spunem cum ne vom distra împreună! "></Card>
        </div>
      </div>
    </div>
  </div>
  <div class="container-white pb-5">
    <div class="container pt-5 mb-4">
      <div class="mx-4">
        <div class="display-7 text-start">
          CE GASESTI PE LA NOI
        </div>
        <div class="text-start pb-3">
          <img src="@/assets/divider.svg"
               height="8"
               width="100"
               alt="Bunker divider">
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2">
            <Card
              :link-to="{ name: 'album', params: { section: 'maze' }}"
              :title-text="'Laser Maze'"
              :img="require('../assets/thumbs/bunker-arena-laser-maze.webp')"
              body-text="Labirintul de lasere este o noua provocare!
Dă-i start, treci printre razele verzi, apasă butonul roșu, schimbă traseul,
strecoară-te înapoi, apasă butonul verde și vezi ce punctaj ai! "></Card>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2">
            <Card
              :title-text="'Karaoke Room'"
              :link-to="{ name: 'album', params: { section: 'karaoke' }}"
              :img="require('../assets/thumbs/bunker-arena-karaoke-room.webp')"
              body-text="O cameră antifonată, echipată cu tot ce trebuie pentru
              a te transforma într-un superstar! "
            ></Card>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <Card position="right"
                  color="yellow"
                  :link-to="{ name: 'album', params: { section: 'all' }}"
                  :img="require('../assets/thumbs/bunker-arena-board-games.webp')"
                  title-text="Board games"
                  body-text="Zeci de jocuri de societate te așteaptă: Catan, Activity,
                  Alias, Cards Against Humanity, Taboo, 5 Secunde, Nume de Cod, Werewolf,
                  Dixit, Jungle Speed, Mascarade, Love Letter, Saboteur, Tick Tac
                  Boom, Ticket to Ride si multe altele! "
            ></Card>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <Card position="left"
                  color="red"
                  :link-to="{ name: 'album', params: { section: 'racing' }}"
                  :img="require('../assets/thumbs/bunker-arena-racing-simulator.webp')"
                  title-text="Racing simulator"
                  body-text="Așează-te, pune-ți VR-ul pe frunte, și apasă pedala!"></Card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Footer from '../components/Footer.vue';
import Card from '../components/Card.vue';
import CoverHome from '../components/CoverHome.vue';

export default {
  name: 'Home',
  components: {
    Card,
    CoverHome,
    Footer,
  },
};
</script>

<style lang="scss">
  .contact {
    transition: all .2s ease-in-out;
    transform: scale(1.1)
  }

</style>
